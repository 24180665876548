.linkGroup {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
}

.linkImg {
	width: 4rem;
	margin: 0 2rem;
}
